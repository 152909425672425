import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/800.css'

import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { CssVarsProvider } from '@mui/joy/styles'
import { CssBaseline, GlobalStyles } from '@mui/joy'

import './main.css'
import { defaultTheme } from './themes'
import App from './components/App'
import * as serviceWorker from './serviceWorker'

const theme = defaultTheme

const globalStyles = (
	<GlobalStyles
		styles={{
			a: {
				color: 'var(--joy-palette-primary-800)',
				fontWeight: 'var(--joy-fontWeight-md)',
			},
		}}
	/>
)

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
	<StrictMode>
		<CssVarsProvider theme={theme}>
			<CssBaseline />
			{globalStyles}
			<App />
		</CssVarsProvider>
	</StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
