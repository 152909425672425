import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

const defaultSignature = {
	name: '',
	email: '',
	title: '',
	phoneNumber: '',
	linkedInHandle: '',
	twitterHandle: '',
	calendlyHandle: '',
}

export const signatureVariantAtom = atomWithStorage(
	'signature-variant',
	'm2north'
)
const tytoSignatureAtom = atomWithStorage('tyto-signature', defaultSignature)
const m2northSignatureAtom = atomWithStorage(
	'm2north-signature',
	defaultSignature
)
const irjSignatureAtom = atomWithStorage('irj-signature', defaultSignature)

export const signatureAtom = atom(
	get => {
		const variant = get(signatureVariantAtom)
		switch (variant) {
			default:
			case 'm2north':
				return get(m2northSignatureAtom)
			case 'tyto':
				return get(tytoSignatureAtom)
			case 'irj':
				return get(irjSignatureAtom)
		}
	},
	(get, set, newSignature) => {
		const variant = get(signatureVariantAtom)
		switch (variant) {
			default:
			case 'm2north':
				set(m2northSignatureAtom, newSignature)
				break
			case 'tyto':
				set(tytoSignatureAtom, newSignature)
				break
			case 'irj':
				set(irjSignatureAtom, newSignature)
				break
		}
	}
)
