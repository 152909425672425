import { useRef, useState } from 'react'
import { useAtom } from 'jotai'

import { Button } from '@mui/joy'
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material'

import SignatureVariantMenu from './SignatureVariantMenu'
import { signatureVariantAtom } from '../signatureFormState'
import { variantToLabel } from '../utils'

const SignatureVariantSelector = () => {
	const [signatureVariant, setSignatureVariant] =
		useAtom(signatureVariantAtom)
	const buttonRef = useRef(null)
	const [open, setOpen] = useState(false)

	const handleClose = () => {
		setOpen(false)
	}

	const handleSelect = value => {
		setSignatureVariant(value)
		handleClose()
	}

	return (
		<>
			<Button
				ref={buttonRef}
				id="signature-variant-button"
				aria-controls={'signature-variant-menu'}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				variant="outlined"
				color="neutral"
				onClick={() => {
					setOpen(true)
				}}
				endDecorator={<ArrowDropDownIcon />}
			>
				{variantToLabel[signatureVariant]}
			</Button>

			<SignatureVariantMenu
				id="signature-variant-menu"
				anchorEl={buttonRef.current}
				open={open}
				onClose={handleClose}
				aria-labelledby="signature-variant-button"
				onSelect={handleSelect}
			/>
		</>
	)
}

export default SignatureVariantSelector
