import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useAtom } from 'jotai'

import {
	FormControl,
	FormLabel,
	Grid,
	Input,
	Link,
	Stack,
	Typography,
} from '@mui/joy'
import {
	Account as AccountIcon,
	CardAccountDetailsOutline as AccountCardDetailsIcon,
	Cellphone as CellphoneAndroidIcon,
	Email as EmailIcon,
	Linkedin as LinkedinIcon,
	Twitter as TwitterIcon,
} from 'mdi-material-ui'
import { signatureAtom } from '../signatureFormState'

const SignatureForm = () => {
	const [storedSignature, setStoredSignature] = useAtom(signatureAtom)
	const { formState, handleSubmit, register, reset, watch } = useForm({
		defaultValues: storedSignature,
		mode: 'onChange',
	})

	const watchAllFields = watch()

	useEffect(() => {
		reset(storedSignature)
	}, [reset, storedSignature])

	useEffect(() => {
		if (formState.isDirty) {
			setStoredSignature(watchAllFields)
		}
	}, [formState.isDirty, reset, setStoredSignature, watchAllFields])

	const submit = data => {
		setStoredSignature(data)
	}

	return (
		<form onSubmit={handleSubmit(submit)}>
			<Grid container spacing={2}>
				<Grid xs={6}>
					<Stack spacing={2}>
						<Typography>Required</Typography>
						<FormControl>
							<FormLabel>Full name</FormLabel>
							<Input
								startDecorator={<AccountIcon />}
								{...register('name')}
							/>
						</FormControl>

						<FormControl>
							<FormLabel>Title</FormLabel>
							<Input
								placeholder={'eg. Ice Cream Server'}
								startDecorator={<AccountCardDetailsIcon />}
								{...register('title')}
							/>
						</FormControl>

						<FormControl>
							<FormLabel>Email</FormLabel>
							<Input
								placeholder={'eg. laurika.prinsloo@m2north.com'}
								startDecorator={<EmailIcon />}
								{...register('email')}
							/>
						</FormControl>
					</Stack>
				</Grid>
				<Grid xs={6}>
					<Stack spacing={2}>
						<Typography>Optional</Typography>
						<FormControl>
							<FormLabel>Mobile number</FormLabel>
							<Input
								placeholder={'eg. +27 82 551-9775'}
								startDecorator={<CellphoneAndroidIcon />}
								{...register('phoneNumber')}
							/>
						</FormControl>
						<FormControl>
							<FormLabel>LinkedIn handle</FormLabel>
							<Input
								placeholder={'eg. justin-lawrence'}
								startDecorator={<LinkedinIcon />}
								{...register('linkedInHandle')}
							/>
						</FormControl>
						<FormControl>
							<FormLabel>Calendly</FormLabel>
							<Input
								placeholder={'eg. ivanj-1/meeting-missinglink'}
								startDecorator={<EmailIcon />}
								{...register('calendlyHandle')}
							/>
						</FormControl>

						<FormControl>
							<FormLabel>Twitter handle</FormLabel>
							<Input
								placeholder={'eg. justin_lawrence'}
								startDecorator={<TwitterIcon />}
								{...register('twitterHandle')}
							/>
						</FormControl>
					</Stack>
				</Grid>
			</Grid>
		</form>
	)
}

export default SignatureForm
