import { Box, Container, Divider, Stack, Typography } from '@mui/joy'

import SignatureVariantSelector from './SignatureVariantSelector'
import SignatureSection from './SignatureSection'
import SignatureForm from './SignatureForm'

function App() {
	return (
		<>
			<Container maxWidth="md" sx={{ py: 3 }}>
				<Stack alignItems={'center'} direction={'row'}>
					<Typography level="h1">Signature Generator</Typography>
					<Box flex={'1 0 0'} />
					<SignatureVariantSelector />
				</Stack>

				<Box py={3}>
					<SignatureSection />
				</Box>

				<Divider />

				<Box py={3}>
					<SignatureForm />
				</Box>
			</Container>
		</>
	)
}

export default App
