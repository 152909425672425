import { Column } from '@react-email/column'
import { Font } from '@react-email/font'
import { Head } from '@react-email/head'
import { Html } from '@react-email/html'
import { Img } from '@react-email/img'
import { Link } from '@react-email/link'
import { Section } from '@react-email/section'
import { Text } from '@react-email/text'

import { useTheme } from '@mui/joy'

import { getAvatarImageUrl } from '../helpers/gravatar'
import {
	variantToBackgroundImage,
	variantToLabel,
	variantToWebsite,
} from '../utils'

const APP_URL = process.env.REACT_APP_URL

const fontWeights = [400, 500, 600, 700]

const m2northPhoneNumbers = {
	uk: '+44 203 322-3849',
	usa: '+1 (213) 263-4077',
	za: '+27 11 856-2000',
}

const SocialIcon = ({ src, style, ...props }) => {
	return (
		<span
			style={{
				display: 'inline-block',
				height: '18px',
				lineHeight: '18px',
				width: '18px',
				...style,
			}}
			{...props}
		>
			<Img
				src={src}
				alt=""
				height={18}
				width={18}
				style={{ display: 'inline-block' }}
			/>
		</span>
	)
}

const SocialLinkButton = ({ href, iconUrl, style, ...props }) => {
	return (
		<Link
			key={'linkedIn-link'}
			href={href}
			style={{ display: 'inline-block', height: '18px', ...style }}
			{...props}
		>
			<SocialIcon src={iconUrl} />
		</Link>
	)
}

const EmailSafeSignature = ({ signature, variant }) => {
	const theme = useTheme()
	const linkedInUrl = `https://www.linkedin.com/in/${signature.linkedInHandle}`
	const twitterUrl = `https://twitter.com/${signature.twitterHandle}`
	const calendlyUrl = `https://calendly.com/${signature.calendlyHandle}`

	const backgroundImage = variantToBackgroundImage[variant]
	const gravatarUrl = getAvatarImageUrl(signature.email)

	const textColor = theme.colorSchemes.light.palette.text.primary

	const contactDetails = []
	signature.phoneNumber &&
		contactDetails.push({
			href: `tel:${signature.phoneNumber}`,
			text: `${signature.phoneNumber}`,
			iconUrl: `${APP_URL}/icons/phone.png`,
		})

	signature.email &&
		contactDetails.push({
			href: `mailto:${signature.email}`,
			text: signature.email,
			iconUrl: `${APP_URL}/icons/email.png`,
		})

	contactDetails.push({
		href: `https://${variantToWebsite[variant]}`,
		text: variantToWebsite[variant],
		iconUrl: `${APP_URL}/icons/website.png`,
	})

	signature.calendlyHandle &&
		contactDetails.push({
			href: calendlyUrl,
			iconUrl: `${APP_URL}/icons/calendly.png`,
			text: `Questions? Schedule a meeting`,
		})

	const socialLinks = []
	signature.linkedInHandle &&
		socialLinks.push({
			href: linkedInUrl,
			iconUrl: `${APP_URL}/icons/linkedin.png`,
		})
	signature.twitterHandle &&
		socialLinks.push({
			href: twitterUrl,
			iconUrl: `${APP_URL}/icons/twitter.png`,
		})

	return (
		<Html lang="en" dir="ltr">
			<Head>
				{fontWeights.map(fontWeight => (
					<Font
						key={fontWeight}
						fontFamily="Poppins"
						fallbackFontFamily="Verdana"
						webFont={{
							url: `https://fonts.googleapis.com/css2?family=Poppins:wght@${fontWeight}&display=swap`,
							format: 'css2',
						}}
						fontWeight={fontWeight}
						fontStyle="normal"
					/>
				))}
			</Head>
			<body>
				<Section id={'signature'}>
					<br />
					<Section
						background={`${APP_URL}/images/${backgroundImage}`}
						style={{
							backgroundRepeat: 'no-repeat no-repeat',
							backgroundPosition: '0% 0%',
							margin: 0,
							height: 168,
						}}
					>
						<Section
							style={{
								paddingLeft: 24,
								width: 'auto',
								margin: 0,
							}}
						>
							{signature.email ? (
								<Column
									width={'80px'}
									style={{
										paddingRight: 24,
										verticalAlign: 'middle',
										width: 80,
									}}
								>
									<Img
										src={gravatarUrl}
										alt=""
										width="96"
										height="96"
										style={{ borderRadius: '50%' }}
									/>
								</Column>
							) : null}

							<Column
								style={{
									paddingRight: '20px',
									textAlign: 'right',
									width: 200,
								}}
							>
								<Text
									style={{
										color: textColor,
										fontSize: theme.fontSize.xl,
										fontWeight: theme.fontWeight.xl,
										margin: 0,
									}}
								>
									{signature.name}
								</Text>
								<Text
									style={{
										color: theme.colorSchemes.light.palette
											.primary['500'],
										fontSize: theme.fontSize.sm,
										fontWeight: theme.fontWeight.lg,
										margin: 0,
									}}
								>
									{signature.title ? (
										<>
											{signature.title}
											<br />
										</>
									) : null}

									{variantToLabel[variant]}
								</Text>

								<Text
									style={{
										height: '18px',
										margin: 0,
										marginTop: 10,
									}}
								>
									{socialLinks.map(({ href, iconUrl }, i) => (
										<SocialLinkButton
											key={i}
											href={href}
											iconUrl={iconUrl}
											style={{ marginLeft: '8px' }}
										/>
									))}
								</Text>
							</Column>
							<Column>
								<div
									style={{
										backgroundImage:
											'linear-gradient(to bottom, #fff 0%, #dee0e0 25%, #dee0e0 75%, #fff 100%)',
										height: 96,
										width: 2,
									}}
								/>
							</Column>
							<Column style={{ paddingLeft: '20px' }}>
								<Text
									style={{
										fontSize: theme.fontSize.sm,
										margin: 0,
										marginTop: 8,
									}}
								>
									{contactDetails.map(
										({ href, iconUrl, text }, i) => (
											<Section
												key={`value-${i}`}
												style={{
													fontSize: theme.fontSize.sm,
													margin: 0,
													marginBottom: 8,
												}}
											>
												<Column style={{ width: 0 }}>
													<SocialIcon
														src={iconUrl}
														style={{
															marginRight: 12,
															verticalAlign:
																'middle',
														}}
													/>
												</Column>
												<Column>
													<Link
														href={href}
														style={{
															color: textColor,
															fontWeight:
																theme.fontWeight
																	.lg,
															textDecoration:
																'none',
														}}
													>
														{text}
													</Link>
												</Column>
											</Section>
										)
									)}
								</Text>
							</Column>
						</Section>
					</Section>
					<Section id={'endNode'}>
						{variant === 'm2north' ? (
							<Text
								style={{
									margin: 0,
									marginBottom: 16,
									marginTop: 16,
								}}
							>
								<Text style={{ margin: 0 }}>
									{Object.entries(m2northPhoneNumbers).map(
										([country, number]) => (
											<span style={{ marginRight: 16 }}>
												<span
													style={{ fontWeight: 600 }}
												>
													{country}
												</span>{' '}
												{number}
											</span>
										)
									)}
								</Text>
							</Text>
						) : null}

						{variant === 'm2north' ? (
							<Img
								src={`${APP_URL}/images/m2north-email-ad.png`}
								alt=""
								width="453"
								height="154"
							/>
						) : variant === 'tyto' ? (
							<Img
								src={`${APP_URL}/images/tyto-email-ad.png`}
								alt=""
								width="453"
								height="154"
							/>
						) : null}

						{variant === 'm2north' ? (
							<Text style={{ margin: 0 }}>
								<Text
									style={{
										fontSize: theme.fontSize.xs,
										margin: 0,
									}}
								>
									M2North disclaims liability for any loss,
									damage or expense however caused, arising
									from use of this email.{' '}
									<Link href="https://www.m2north.com/email-disclaimer">
										Full Disclaimer.
									</Link>
								</Text>
							</Text>
						) : variant === 'tyto' ? (
							<Text style={{ margin: 0, marginTop: 16 }}>
								<Text
									style={{
										fontSize: theme.fontSize.xs,
										margin: 0,
									}}
								>
									Tyto disclaims liability for any loss,
									damage or expense however caused, arising
									from use of this email.{' '}
									<Link href="https://www.tyto.me/legal/email-disclaimer/">
										Full Disclaimer.
									</Link>
								</Text>
							</Text>
						) : null}
					</Section>
				</Section>
			</body>
		</Html>
	)
}

export default EmailSafeSignature
