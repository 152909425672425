import { extendTheme } from '@mui/joy/styles'

export const m2northPrimary = {
	50: '#E9F8FF',
	100: '#B9E9FE',
	200: '#8AD9FE',
	300: '#5ACAFD',
	400: '#2BBBFC',
	500: '#03A9F4',
	600: '#0393D4',
	700: '#027CB3',
	800: '#026693',
	900: '#015073',
}

export const tytoPrimary = {
	50: '#FEEFF4',
	100: '#FDC6D8',
	200: '#FB9DBC',
	300: '#FA75A0',
	400: '#F84C84',
	500: '#F72368',
	600: '#EB0952',
	700: '#C60745',
	800: '#A10638',
	900: '#7C042B',
}

export const irjPrimary = {
	50: '#FFF9EA',
	100: '#FEEDBC',
	200: '#FDE18F',
	300: '#FCD561',
	400: '#FCC833',
	500: '#FBBC05',
	600: '#DAA303',
	700: '#B98A03',
	800: '#977102',
	900: '#755802',
}

export const defaultThemeOptions = {
	colorSchemes: {
		light: {
			palette: {
				primary: m2northPrimary,
				text: {
					primary: '#1e3340',
				},
			},
			background: {
				body: 'var(--joy-palette-neutral-100)',
			},
		},
	},
	fontFamily: {
		body: 'Poppins, var(--joy-fontFamily-fallback)',
		display: 'Poppins, var(--joy-fontFamily-fallback)',
	},
	typography: {
		h1: {
			fontWeight: 'var(--joy-fontWeight-xl)',
		},
	},
	components: {
		JoyLink: {
			defaultProps: {
				underline: 'hover',
			},
			styleOverrides: {
				root: {
					color: 'var(--joy-palette-primary-800)',
					fontWeight: 'var(--joy-fontWeight-md)',
				},
			},
		},
	},
}

export const defaultTheme = extendTheme(defaultThemeOptions)
