import { Menu, MenuItem } from '@mui/joy'

const SignatureVariantMenu = ({ onSelect, ...props }) => {
	const handleSelect = value => () => {
		onSelect(value)
	}

	return (
		<Menu {...props}>
			<MenuItem onClick={handleSelect('m2north')}>M2North</MenuItem>
			<MenuItem onClick={handleSelect('tyto')}>Tyto</MenuItem>
			<MenuItem onClick={handleSelect('irj')}>IRJ.io</MenuItem>
		</Menu>
	)
}

export default SignatureVariantMenu
