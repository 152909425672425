import { extendTheme } from '@mui/joy/styles'
import { deepmerge } from '@mui/utils'

import {
	defaultThemeOptions,
	irjPrimary,
	m2northPrimary,
	tytoPrimary,
} from './themes'

export const variantToBackgroundImage = {
	m2north: 'avatar-background-half-hexagon-blue.png',
	tyto: 'avatar-background-half-circle-pink.png',
	irj: 'avatar-background-half-hexagon-yellow.png',
}

export const variantToWebsite = {
	m2north: 'www.m2north.com',
	tyto: 'www.tyto.me',
	irj: 'www.irj.io',
}

export const variantToLabel = {
	m2north: 'M2North',
	tyto: 'Tyto',
	irj: 'IRJ.io',
}

export const variantToTheme = {
	m2north: extendTheme(
		deepmerge(defaultThemeOptions, {
			colorSchemes: { light: { palette: { primary: m2northPrimary } } },
		})
	),
	tyto: extendTheme(
		deepmerge(defaultThemeOptions, {
			colorSchemes: { light: { palette: { primary: tytoPrimary } } },
		})
	),
	irj: extendTheme(
		deepmerge(defaultThemeOptions, {
			colorSchemes: { light: { palette: { primary: irjPrimary } } },
		})
	),
}
