const md5 = require('md5')

export const AVATAR_URL = '//www.gravatar.com/avatar/'

/**
 * Build up the full gravatar image url
 *
 * @param {string} email Can be an email or a md5 hash of an email.
 * @return {string}
 */
export const getAvatarImageUrl = email => {
	const query = ['r=pg', 'd=mp', 's=144']
	const hash = md5(email.toLowerCase())

	return `${AVATAR_URL}${hash}?${query.join('&')}`
}
