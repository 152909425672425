import { useEffect, useState } from 'react'
import { useAtomValue } from 'jotai'

import {
	Button,
	CssVarsProvider,
	IconButton,
	Link,
	Sheet,
	Tooltip,
	Typography,
} from '@mui/joy'

import {
	ContentCopy as ContentCopyIcon,
	Plus as PlusIcon,
} from 'mdi-material-ui'

import { signatureAtom, signatureVariantAtom } from '../signatureFormState'
import EmailSafeSignature from './EmailSafeSignature'
import { defaultTheme } from '../themes'
import { variantToTheme } from '../utils'

const SignatureSection = () => {
	const signature = useAtomValue(signatureAtom)
	const signatureVariant = useAtomValue(signatureVariantAtom)
	const [copyText, setCopyText] = useState('Copy')
	const [theme, setTheme] = useState(defaultTheme)

	useEffect(() => {
		const theme = variantToTheme[signatureVariant]
		setTheme(theme)
	}, [signatureVariant])

	const selectSignature = () => {
		const range = document.createRange()
		const startNode = document.getElementById('signature').childNodes[0]
		const startOffset = 0
		range.setStart(startNode, startOffset)

		const endNode = document.getElementById('endNode')
		const endOffset = endNode.childNodes.length
		range.setEnd(endNode, endOffset)

		const selection = window.getSelection()
		selection.removeAllRanges()
		selection.addRange(range)

		document.execCommand('copy')

		selection.removeAllRanges()

		setCopyText('Copied!')

		setTimeout(() => {
			setCopyText('Copy')
		}, 1500)
	}

	return (
		<CssVarsProvider theme={theme}>
			<Sheet
				sx={{
					position: 'relative',
				}}
			>
				<EmailSafeSignature
					signature={signature}
					variant={signatureVariant}
				/>

				<Tooltip title={'Update your gravatar'}>
					<IconButton
						component="a"
						aria-label="Add"
						href="https://www.gravatar.com"
						rel="noopener noreferrer"
						target="_blank"
						size={'sm'}
						variant="solid"
						sx={{
							position: 'absolute',
							left: 92,
							top: 132,
						}}
					>
						<PlusIcon />
					</IconButton>
				</Tooltip>

				<Button
					aria-label="Copy"
					onClick={selectSignature}
					startDecorator={<ContentCopyIcon />}
					sx={{
						position: 'absolute',
						right: 0,
						bottom: 0,
					}}
				>
					{copyText}
				</Button>
			</Sheet>
		</CssVarsProvider>
	)
}

export default SignatureSection
